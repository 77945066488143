// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Form.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$(),
  _s5 = $RefreshSig$(),
  _s6 = $RefreshSig$(),
  _s7 = $RefreshSig$(),
  _s8 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Form.tsx");
  import.meta.hot.lastModified = "1717594406439.2031";
}
// REMIX HMR END

import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { cn } from '~/utils/cn';
import { Label } from './Label';
const FormMain = FormProvider;
const FormFieldContext = React.createContext({});
function FormField({
  ...props
}) {
  _s();
  const context = React.useMemo(() => ({
    name: props.name
  }), [props.name]);
  return <FormFieldContext.Provider value={context}>
      <Controller {...props} />
    </FormFieldContext.Provider>;
}
_s(FormField, "5O8ij4BBqEa+dP2kIvnsZK/gTzU=");
_c = FormField;
const useFormField = () => {
  _s2();
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const {
    getFieldState,
    formState
  } = useFormContext();
  const fieldState = getFieldState(fieldContext.name, formState);
  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }
  const {
    id
  } = itemContext;
  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};
_s2(useFormField, "eRzki+X5SldVDcAh3BokmSZW9NU=", false, function () {
  return [useFormContext];
});
const FormItemContext = React.createContext({});
const FormItem = _s3(React.forwardRef(_c2 = _s3(({
  className,
  ...props
}, ref) => {
  _s3();
  const id = React.useId();
  const context = React.useMemo(() => ({
    id
  }), [id]);
  return <FormItemContext.Provider value={context}>
      <div ref={ref} className={cn(className)} {...props} />
    </FormItemContext.Provider>;
}, "L1JrilKQKEWCZOh0bJw6DQaSaW8=", false, function () {
  return [React.useId];
})), "L1JrilKQKEWCZOh0bJw6DQaSaW8=", false, function () {
  return [React.useId];
});
_c3 = FormItem;
FormItem.displayName = 'FormItem';
const FormLabel = _s4(React.forwardRef(_c4 = _s4(({
  className,
  children,
  ...props
}, ref) => {
  _s4();
  const {
    error,
    formItemId
  } = useFormField();
  return <Label ref={ref} className={cn('w-full', error && 'text-destructive', className)} htmlFor={formItemId} {...props}>
      <span className='flex items-baseline justify-between'>{children}</span>
    </Label>;
}, "Z4R+rKjylfAcqmbRnqWEg1TfTcg=", false, function () {
  return [useFormField];
})), "Z4R+rKjylfAcqmbRnqWEg1TfTcg=", false, function () {
  return [useFormField];
});
_c5 = FormLabel;
FormLabel.displayName = 'FormLabel';
const FormHint = _s5(React.forwardRef(_c6 = _s5(({
  className,
  ...props
}, ref) => {
  _s5();
  const {
    error
  } = useFormField();
  return <span ref={ref} className={cn('ml-auto max-w-full text-xs font-light text-secondary peer-disabled:cursor-not-allowed peer-disabled:opacity-70', error && 'text-destructive', className)} {...props} />;
}, "DM6IgwyeaGhIxbI8OQHJBCieqTk=", false, function () {
  return [useFormField];
})), "DM6IgwyeaGhIxbI8OQHJBCieqTk=", false, function () {
  return [useFormField];
});
_c7 = FormHint;
FormHint.displayName = 'FormHint';
const FormControl = _s6(React.forwardRef(_c8 = _s6(({
  ...props
}, ref) => {
  _s6();
  const {
    error,
    formItemId,
    formDescriptionId,
    formMessageId
  } = useFormField();
  return <Slot ref={ref} id={formItemId} aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`} aria-invalid={!!error} {...props} />;
}, "mI3rlmONcPPBVtOc6UefMrXAJ6w=", false, function () {
  return [useFormField];
})), "mI3rlmONcPPBVtOc6UefMrXAJ6w=", false, function () {
  return [useFormField];
});
_c9 = FormControl;
FormControl.displayName = 'FormControl';
const FormDescription = _s7(React.forwardRef(_c10 = _s7(({
  className,
  ...props
}, ref) => {
  _s7();
  const {
    formDescriptionId
  } = useFormField();
  return <p ref={ref} id={formDescriptionId} className={cn('text-sm text-gray-500', className)} {...props} />;
}, "573aRXA8dloSrMaQM9SdAF4A9NI=", false, function () {
  return [useFormField];
})), "573aRXA8dloSrMaQM9SdAF4A9NI=", false, function () {
  return [useFormField];
});
_c11 = FormDescription;
FormDescription.displayName = 'FormDescription';
const FormMessage = _s8(React.forwardRef(_c12 = _s8(({
  className,
  children,
  ...props
}, ref) => {
  _s8();
  const {
    error,
    formMessageId
  } = useFormField();
  const body = error ? String(error?.message) : children;
  if (!body) {
    return null;
  }
  return <p ref={ref} id={formMessageId} className={cn('mt-1 text-sm font-medium text-destructive', className)} {...props}>
      {body}
    </p>;
}, "WONNS8VCMr8LShuUovb8QgOmMVY=", false, function () {
  return [useFormField];
})), "WONNS8VCMr8LShuUovb8QgOmMVY=", false, function () {
  return [useFormField];
});
_c13 = FormMessage;
FormMessage.displayName = 'FormMessage';
export const Form = Object.assign(FormMain, {
  Item: FormItem,
  Label: FormLabel,
  Hint: FormHint,
  Control: FormControl,
  Description: FormDescription,
  Message: FormMessage,
  Field: FormField
});
_c14 = Form;
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12, _c13, _c14;
$RefreshReg$(_c, "FormField");
$RefreshReg$(_c2, "FormItem$React.forwardRef");
$RefreshReg$(_c3, "FormItem");
$RefreshReg$(_c4, "FormLabel$React.forwardRef");
$RefreshReg$(_c5, "FormLabel");
$RefreshReg$(_c6, "FormHint$React.forwardRef");
$RefreshReg$(_c7, "FormHint");
$RefreshReg$(_c8, "FormControl$React.forwardRef");
$RefreshReg$(_c9, "FormControl");
$RefreshReg$(_c10, "FormDescription$React.forwardRef");
$RefreshReg$(_c11, "FormDescription");
$RefreshReg$(_c12, "FormMessage$React.forwardRef");
$RefreshReg$(_c13, "FormMessage");
$RefreshReg$(_c14, "Form");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;